//viewQuotation
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import { ToWords } from "to-words";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
  TableContainer,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import "./viewQuotation.css";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";
import { viewQuotationGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import CustomPDFViewer from "../trainingResources/CustomPDFViewer";
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      fontSize:"11.5px",
      fontFamily: "Times New Roman",
    },
  },
  row: {
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  cell50: {
    padding: 1,
    width: "50%",
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  srCell: {
    padding: 1,
    width: 50,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },

  nameCell: {
    padding: 1,
    width: 130,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  rangeCell: {
    padding: 1,
    width: 50,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  qntCell: {
    padding: 1,
    width: 30,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  rateCell: {
    padding: 1,
    width: 50,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  boldCss: {
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  termsboldCss: {
    fontSize:"10.5px",
    fontFamily: "Times New Roman",
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
  spacialCaseBorder: {
    borderBottom: "1px solid black !important",
    fontSize:"11.5px",
    fontFamily: "Times New Roman",
  },
});

function InstrumentTable({
  classes,
  quotationProductDetails,
  productList1,
  quotationAllData,
}) {
  const toWords = new ToWords();
  let productDeatails = quotationProductDetails;
  let productList = productList1;
  const hasDiscount =
    Array.isArray(productDeatails) &&
    productDeatails?.some((product) => product?.discount !== null);

  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    courierCharges: 0,
    grandTotal: 0,
    discount: 0,
    totalAmountbeforediscount: 0,
  };

  if (productDeatails.length > 0 && productList.length > 0) {
    for (let i = 0; i < productDeatails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDeatails[i].equipmentId
      );
      productDeatails[i].name = result?.instrumentName;
      let totalAmount = productDeatails[i].rate * productDeatails[i].quantity;
      let discountAmount =
        (totalAmount * (parseFloat(productDeatails[i]?.discount) || 0)) / 100;
      productDeatails[i].amount = totalAmount - discountAmount;
      amounts.totalAmountBeforeTax += productDeatails[i].amount;
    }
    amounts.conveyanceCharges = parseInt(
      quotationAllData[0]?.conveyanceCharges
        ? quotationAllData[0]?.conveyanceCharges
        : "0"
    );
    amounts.courierCharges = parseInt(
      quotationAllData[0]?.courierCharges
        ? quotationAllData[0]?.courierCharges
        : "0"
    );
    amounts.totalAmountBeforeTax +=
      amounts.conveyanceCharges + amounts.courierCharges;

    amounts.totalAmountbeforediscount = amounts.totalAmountBeforeTax;
    amounts.discount = quotationAllData[0]?.discount;
    let discountcal = (amounts.totalAmountBeforeTax * amounts.discount) / 100;

    amounts.totalAmountBeforeTax = amounts.totalAmountBeforeTax - discountcal;

    const gstType = quotationAllData[0]?.gstType;
    const gstPercentages = quotationAllData[0]?.gstpercentages;

    if (gstType === 1) {
      const percentage = gstPercentages ? gstPercentages / 2 : 9;
      amounts.sgst = amounts.cgst =
        (amounts.totalAmountBeforeTax * percentage) / 100;
      amounts.gst = amounts.sgst + amounts.cgst;
    } else if (gstType === 2) {
      amounts.gst =
        (amounts.totalAmountBeforeTax * (gstPercentages || 18)) / 100;
    } else {
      amounts.gst = 0;
    }
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;
  }

  return (
    <Table mt={5} className={["single-border-table", classes.table]}>
      <TableRow style={{ backgroundColor: "rgba(156,194,230,255)" }} id="pagbreaksborder">
        <TableCell className={classes.srCell} align="center">
          <b className={classes.boldCss}>Sr. No</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b className={classes.boldCss}>Description of Item | Equipment - Range</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b className={classes.boldCss}>Description</b>
        </TableCell>
        <TableCell className={classes.makeCell} align="center">
          <b className={classes.boldCss}>SAC | HSN Code Job Type</b>
        </TableCell>
        <TableCell className={classes.rangeCell} align="center">
          <b className={classes.boldCss}>Type of Service</b>
        </TableCell>
        <TableCell className={classes.rangeCell} align="center">
          <b className={classes.boldCss}>Rate</b>
        </TableCell>
        <TableCell className={classes.qntCell} align="center">
          <b className={classes.boldCss}>Quantity (No)</b>
        </TableCell>
        {hasDiscount && (
          <TableCell className={classes.qntCell} align="center">
            <b className={classes.boldCss}>Discount (%)</b>
          </TableCell>
        )}
        <TableCell className={classes.rateCell} align="center">
          <b className={classes.boldCss}>Total Price (INR)</b>
        </TableCell>
      </TableRow>
      {productList &&
        productDeatails?.map((product, i) => (
          <TableRow className={classes.row} key={i} id="pagbreaksborder">
            <TableCell className={classes.srCell} align="center">
              {i + 1}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
              {product?.requestedName ? product?.requestedName : product?.name}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
            {product?.rangeValue?.replace(/#/g, ' ')?.replace(/\|/g, ' to ')?.replace(/\s+/g, ' ')?.trim()}  &nbsp;   {product?.description}
            </TableCell>
            <TableCell className={classes.makeCell} align="center">
              {product?.hsnCode}
            </TableCell>
            <TableCell className={classes.rangeCell} align="center">
              {product?.service}
            </TableCell>
            <TableCell className={classes.qntCell} align="center">
              {product?.rate?.toFixed(2)}
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              {product?.quantity}
            </TableCell>
            {hasDiscount ? (
              product?.discount !== null ? (
                <TableCell className={classes.rateCell} align="center">
                  {product?.discount} %
                </TableCell>
              ) : (
                <TableCell
                  className={classes.rateCell}
                  align="center"
                ></TableCell>
              )
            ) : null}
            <TableCell className={classes.rateCell} align="center">
              {product?.amount?.toFixed(2)}
            </TableCell>
          </TableRow>
        ))}
      {amounts.conveyanceCharges !== 0 && (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b className={classes.boldCss}>Onsites Charges &nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b className={classes.boldCss}>
              {" "}
              {amounts.conveyanceCharges
                ? amounts.conveyanceCharges?.toFixed(2)
                : 0}
            </b>
          </TableCell>
        </TableRow>
      )}
      {amounts.courierCharges !== 0 && (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b className={classes.boldCss}> Courier Charges &nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b className={classes.boldCss}>
              {amounts.courierCharges ? amounts.courierCharges?.toFixed(2) : 0}
            </b>
          </TableCell>
        </TableRow>
      )}
       <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 8 : 7}
          align="right"
        >
          <b className={classes.boldCss}>Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b className={classes.boldCss}>{amounts.totalAmountbeforediscount?.toFixed(2)}</b>
        </TableCell>
      </TableRow>
      {amounts?.discount ? (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b className={classes.boldCss}>Discount&nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b className={classes.boldCss}>{amounts?.discount ? `${amounts?.discount} %` : ""}</b>
          </TableCell>
        </TableRow>
      ) : (
        ""
      )}
       {amounts?.discount && (
      <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 8 : 7}
          align="right"
        >
          <b className={classes.boldCss}>Total Amount After Discount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b className={classes.boldCss}>{amounts.totalAmountBeforeTax?.toFixed(2)}</b>
        </TableCell>
      </TableRow>
      )}
      {quotationAllData?.[0]?.gstType === 1 && (
        <>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b className={classes.boldCss}>{`CGST(${(quotationAllData?.[0]?.gstpercentages || 18) / 2}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b className={classes.boldCss}>{(amounts?.gst / 2)?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b className={classes.boldCss}>{`SGST(${(quotationAllData?.[0]?.gstpercentages || 18) / 2}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b className={classes.boldCss}>{(amounts?.gst / 2)?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
        </>
      )}
      {(quotationAllData?.[0]?.gstType === 2 ) && (
        <>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b className={classes.boldCss}>{`IGST(${quotationAllData?.[0]?.gstpercentages || 18}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b className={classes.boldCss}>{amounts.gst?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
        </>
      )}
      <TableRow style={{ backgroundColor: "rgba(156,194,230,255)" }} id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 8 : 7}
          align="right"
        >
          <b className={classes.boldCss}>Grand Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b className={classes.boldCss}>{amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : ""}</b>
        </TableCell>
      </TableRow>
      <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 9 : 8}
          align="left"
        >
          <div>
            <b style={{  padding: "1px 5px" }}>
              &nbsp; Amount in Words:
            </b>
            <b className={classes.boldCss}>
              &nbsp;
              {toWords.convert(
                amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : 0,
                { currency: true }
              )}
            </b>
          </div>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default function EditQuotation() {
  const viewQuotationGuides = viewQuotationGuide();
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [asPerAttach, setAsPerAttach] = React.useState(false);
  const [details, setDetails] = React.useState({
    companyName: "",
    companyNumber: "",
    clientName: "",
    address: "",
    referenceNumber: "",
    quotationNumber: "",
    subject: "",
    Date: "",
    thankYouForEnquiry: "",
    termsConditions: "",
    clientId: "",
    conveyanceCharges: "",
    gstNumber: "",
    email: "",
  });
  const [quotationProductDetails, setProductDetails] = React.useState([]);
  const [quotationAllData, setquotatioAllData] = React.useState([]);
  const [settings, setSettings] = React.useState({});
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [contact, setContact] = React.useState({
    contname: "",
    email: "",
    contactNumber: "",
  });
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [88, 1, 10, 1],
          filename: `certificate_${params.id}.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 2.5,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: 'png', quality: 0.4 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

         // border add for contnet
         const borderWidth = 0.6;
         const leftMargin = 15;
         const borderX = leftMargin;
         const borderY = 85;
         const rightMargin = 595 - leftMargin * 0.95;
         pdf.setLineWidth(borderWidth);
         pdf.rect(borderX, borderY, rightMargin - leftMargin, 745);

        // let image = await generateDynamicHeader(
        //   ulrNumber,
        //   certificateNumber,
        //   i,
        //   totalPages
        // );
        // pdf.addImage(image, 0, 70, 595, 55);

        let image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 50, 595, 55);

        if (letterHead) {
          let image = await generateLetterHeadHeader();
          pdf.addImage(image, 0, 0, 595, 70);

          // image = await generateLetterHeadFooter();
          // pdf.addImage(image, 0, 785, 595, 58);
        }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${quotationAllData[0]?.companyName}_${params.quotationNumber}.pdf`;
      a.click();
      // pdf.save();
      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  function fetchSettings() {
    axiosWithToken.get(BASE_URL + `settings`).then((res) => {
      let _settingList = {};
      res.data.forEach((setting) => {
        _settingList[setting.keyName] = setting.value;
      });
      setSettings(_settingList);
    }).catch((err)=>{
      if (err.message !== "request_aborted") {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchSettings" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
      }
    })
  }
  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from instrumentPriceList as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getProductList" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getQuat = () => {
    let url = BASE_URL;
    let paylaod = {
      query: `SELECT qt.*,qt.address as qt_address,qt.id as qt_id, client.* FROM quotation AS qt JOIN clients AS client ON qt.clientId = client.id WHERE qt.clientId = ${params.clientId} AND qt.quotationNumber LIKE '${params.quotationNumber}'`,
    };
    axiosWithToken
      .post(url + "dynamic", paylaod)
      .then(async (res) => {
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 1 AND referenceId = ${res.data[0]?.qt_id}`,
        };
        const response = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery
        );
        setProductDetails(response.data);
        let contact = res.data[0].contact
          ? JSON.parse(res.data[0].contact)
          : null;
        setquotatioAllData(res.data);
        setContact({
          contname: contact && contact.length > 0 ? contact[0]?.contactPersonName || "" : "",
          email: contact && contact.length > 0 ? contact[0]?.emailId || "" : "",
          contactNumber: contact && contact.length > 0 ? contact[0]?.contact || "" : "",
        });
      })
      .catch((err) => {
        console.log("getquat", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getQuat" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
        }
      });
  };
  useEffect(() => {
    // getQuotations();
    fetchSettings();
    getProductList();
    getQuat();
  }, []);

  useEffect(async () => {
    let splitAddress = quotationAllData[0]?.qt_address?.split(",");
    let newAddressArray = await fetchAddresses(
      splitAddress?.[0],
      splitAddress?.[1]
    );
    setNewAddressArray(newAddressArray);
  }, [quotationAllData[0]?.qt_address]);

  return (
    <>
      <Paper sx={{ mx: 5, p: 2 }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                viewQuotationGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view_letterhead"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
                // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view__electronicsign"
              options={[
                { id: 1, label: "With Electronically Signed" },
                { id: 2, label: "Without  Electronically Signed" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Electronically Signed" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Electronically Signed" ||
                  newInputValue === ""
                ) {
                  setElectronicSign(true);
                } else if (newInputValue === "Without  Electronically Signed") {
                  setElectronicSign(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Autocomplete
                    size="small"
                    options={[
                      { id: 1, label: "As per attached list" },
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="As per attached list" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      if (
                        newInputValue === "As per attached list" ||
                        newInputValue === ""
                      ) {
                        setAsPerAttach(true);
                      }
                    }}
                  />
                </Grid>
          <Grid item xs={3}>
            <Button
              id="quotation_view_Download"
              variant="contained"
              size="small"
              sx={{ ml: 3 }}
              disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
        <br />
        <div
          className="invoice"
          ref={printComponentRef}
          id="quotation_view__print"
        >
          {quotationAllData ? (
            <div
              style={{
                width: "95%",
                // border: "1px solid black",
                textAlign: "center",
                margin: "0px auto",
                padding: "10px 10px 5px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  md={12}
                  className="font-link"
                  align="center"
                  justifyContent={"center"}
                >
                  <h5 style={{ color: "#000066", margin: "5px 0px" }}>
                    <b className={classes.boldCss}>QUOTATION FOR CALIBRATION & MAPPING SERVICES</b>
                  </h5>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, fontSize: "0.875rem", fontWeight: 400 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Customer</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none",fontFamily: "Times New Roman",fontSize:"11.5px", }}
                      >
                        {quotationAllData[0]?.companyName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Enquiry ID | Date</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%", fontFamily: "Times New Roman",fontSize:"11.5px",  }}>
                       {quotationAllData[0]?.enquiryId} | {quotationAllData[0]?.enquiryDate ? moment(quotationAllData[0]?.enquiryDate).format(
                          "DD/MM/YYYY"
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Billing Address</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none",fontFamily: "Times New Roman",fontSize:"11.5px",  }}
                      >
                        {newAddressArray?.[0]?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Quotation Number | Date</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%",fontFamily: "Times New Roman",fontSize:"11.5px", }}>
                      {quotationAllData[0]?.quotationNumber + (quotationAllData[0]?.revisionNumber > 0 ? `-Rev-${quotationAllData[0]?.revisionNumber}` : "") } |{" "}
                        {moment(quotationAllData[0]?.date).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Contact Person</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none",fontFamily: "Times New Roman",fontSize:"11.5px",  }}
                      >
                        {contact?.contname}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          borderRight: "none",
                          width: "22%",
                        }}
                      >
                        <b className={classes.boldCss}>Contact Number</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" ,fontFamily: "Times New Roman",fontSize:"11.5px", }}>
                        {contact?.contactNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>E-mail</b>
                      </TableCell>
                      <TableCell align="left" style={{ borderRight: "none",fontFamily: "Times New Roman",fontSize:"11.5px",  }}>
                        {contact?.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Reference Number</b>
                      </TableCell>
                      <TableCell align="left" style={{fontFamily: "Times New Roman",fontSize:"11.5px",}}>
                        {quotationAllData[0]?.referenceNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Quotation Validity</b>
                      </TableCell>
                      <TableCell align="left" style={{ borderRight: "none" }}>
                        {quotationAllData[0]?.quotationValidity}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Equipment Delivery</b>
                      </TableCell>
                      <TableCell align="left" style={{fontFamily: "Times New Roman",fontSize:"11.5px",}}>
                        {quotationAllData[0]?.equipmentDelivery}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          borderRight: "none",
                          fontFamily: "Times New Roman",fontSize:"11.5px",
                        }}
                      >
                        {newAddressArray?.[0]?.shipping?.address ? (
                          <b className={classes.boldCss}>Shipping Address</b>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderRight: "none",
                          fontFamily: "Times New Roman",fontSize:"11.5px",
                        }}
                      >
                        {newAddressArray?.[0]?.shipping?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "600",
                          // backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b className={classes.boldCss}>Equipment Collection</b>
                      </TableCell>
                      <TableCell align="left" style={{fontFamily: "Times New Roman",fontSize:"11.5px",}}>
                        {quotationAllData[0]?.equipmentCollection}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  // backgroundColor: "#D9D9D9",
                  textAlign: "left",
                  border: "1px solid black",
                  padding: "3px 10px",
                  display: "flex",
                  gap: "0px",
                  flexDirection: "column",
                  fontSize: "13px",
                  borderBottom: "none",
                  fontFamily: "Times New Roman"
                }}
              >
                <b className={classes.boldCss}>Dear Customer:</b>
                <b style={{ fontWeight: 500 ,fontFamily: "Times New Roman"}}>
                  Thank you for the enquiry. Please contact our Business
                  Associate for any further assistance on Calibration | Testing
                  Services at:
                </b>
                <b className={classes.boldCss}>
                  Enquiry: +91 95746 21760 | Quotation: +91 97129 28551 |
                  Operations: +91 95746 21760
                </b>
              </div>

              {asPerAttach && (
                <table
                  style={{
                    width: "100%",
                    margin: "0px auto",
                    fontSize: "13px",
                  }}
                  className="srftable"
                >
                  <thead>
                    <tr>
                      <th colSpan={11} style={{ padding: "5px" , borderBottom:"none"}}>
                        <b>QUOTATION DETAILS AS PER ATTACHED LIST</b>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}

              <InstrumentTable
                classes={classes}
                quotationProductDetails={quotationProductDetails}
                productList1={productList}
                quotationAllData={quotationAllData}
              ></InstrumentTable>              

              <div
                style={{
                  fontSize: "13px",
                  pageBreakBefore:"always",
                  paddingTop: "2px",
                  fontFamily: "Times New Roman"
                }}
              >
                <div className="font-link-footer mt-1">
                  <h6>
                    <b className={classes.boldCss}>Terms of Service</b>
                  </h6>
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      padding: "1px 5px",
                      textAlign: "justify",
                    }}
                  >
                    {TermsService(classes)}
                    {/* {quotationAllData[0]?.termsConditions ||
                      settings["quotation terms"]} */}
                  </div>
                </div>
              </div>

                {asPerAttach && (
                  <ResourceModel
                resourceUrl={quotationAllData[0]?.fileUpload}
                />
                )}
            </div>
          ) : (
            <div>Loading...</div>
          )}

          <div style={{ width: "100%" }} id="header-section"></div>
        </div>
        {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Quotation"}
          />
        )}
          {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
      </Paper>
    </>
  );
}

function ResourceModel({ resourceUrl }) {
  const isPDF = resourceUrl && resourceUrl?.endsWith(".pdf");
  const isImage =
    resourceUrl &&
    (resourceUrl?.endsWith(".jpg") ||
      resourceUrl?.endsWith(".jpeg") ||
      resourceUrl?.endsWith(".png"));

  return (
    <div style={{ margin: "3px", pageBreakBefore:"always" }}>
        <WatermarkedContent>
        {isPDF ? (
          <CustomPDFViewer url={resourceUrl} height="100%" canvasHeight={"900px"} />
        ) : isImage ? (
          <img
            src={resourceUrl}
            alt="Resource"
            style={{ width: "100%", height: "100%" }}
          />
        ) : null // Return null for unsupported resource types
        }
        </WatermarkedContent>
    </div>
  );
}

const WatermarkedContent = ({ children }) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {children}
    </div>
  );
};

const TermsService = (classes) => {
  return (
    <div style={{ fontSize: "11.5px", fontFamily: "Times New Roman" }}>
      <ul>
        <li className={classes.termsboldCss}>
          - All calibration will be done/traceable by us or other well known NABL laboratories.
        </li>
        <li className={classes.termsboldCss}>
          - Rates may vary if range/accuracy/quantity vary and are valid only if
          full work is given.
        </li>
      </ul>
      <b className={classes.termsboldCss}>
        Salient Feature of KTS for NABL Calibration: –
      </b>
      <ul>
        <li className={classes.termsboldCss}>
          - Our Laboratory is NABL Accredited for Pressure, Sound, Humidity and
          Thermal parameters, RPM, Electro-Technical, Force, Flow, Mass, Volume,
          Balance, etc.
        </li>
        <li className={classes.termsboldCss}>
          - Each NABL certificate will accompany measurement uncertainty as per
          NABL-141.
        </li>
        <li className={classes.termsboldCss}>
          - We follow Indian/International standards for calibration of
          instruments and ISO 17025:2017 for calibration requirements.
        </li>
        <li className={classes.termsboldCss}>
          - Regular recall notice will be sent one month in advance with respect
          to due date of calibration.
        </li>
        <li>
          <strong>- Prerequisite for Onsite Calibration: </strong>
          <ul>
            <li className={classes.termsboldCss}>
              - The customer is requested to take out the instruments and keep
              ready for calibration or provide 1 or 2 persons to open and fit
              all your pressure gauges from their actual places. This may
              require your user/operator for critical requirements/areas or for
              witness. A clean A/C room with a table for proper workspace and
              stabilized electric supply points should be provided.
            </li>

            <li className={classes.termsboldCss}>
              -{" "}
              <strong>
                Note (*): If there is any issue during mapping by us
              </strong>{" "}
              after our sensor logging starts and sensors are placed in the
              equipment for mapping, and data is not taken or properly read, we
              will be responsible for the same{" "}
              <strong>
                and no charge will be applied. If any issue occurs from your
                company,
              </strong>{" "}
              such as power not being maintained as per condition, or any
              technical issue with your equipment or its environmental
              conditions not being maintained or failing, we will not be
              responsible,<strong> and charges will be applied.</strong>
            </li>

            <li className={classes.termsboldCss}>
              - Rest of the all standard Terms and Conditions as mentioned below
              are applicable.
            </li>
          </ul>
        </li>
      </ul>
      <b className={classes.termsboldCss}>Terms & Conditions: -</b>
      <ul>
        <li className={classes.termsboldCss}>
          - GST 18% will be added for particular work of items. KTS GST No.:
          24ABBFK2634C1ZX & HSN code: 998346
        </li>
        <li className={classes.termsboldCss}>
          - Rate only for calibration purpose. For repair/servicing, it will be
          charged extra.
        </li>
        <li className={classes.termsboldCss}>
          - Work Order should be in the name of “Kesar Testing Service LLP”.
        </li>
        <li className={classes.termsboldCss}>
          - <strong>Payment:</strong> 100% against invoice and certificate
          submitted & Cheque in favor of 'Kesar Testing Service LLP'.
        </li>
        <li className={classes.termsboldCss}>
          - <strong>Packing:</strong> While sending, the instruments must be
          cleaned and well packed. If the instrument is to be sent by courier,
          it will be sent in the same packing, which was received, from the
          customer.
        </li>
        <li className={classes.termsboldCss}>
          - <strong>Delivery:</strong> Duly calibrated instruments can be
          collected by you in three working days. (If the instruments are to be
          dispatched by us through courier, then the courier charges will be
          paid by the customer.)
        </li>
        <li className={classes.termsboldCss}>
          - <strong>Calibration Frequency:</strong> Recommended calibration due
          date will be incorporated subject to pre-requisition from customer as
          per ISO 17025 (Maximum one year). If calibration frequency is not
          specified by the customer, then we will not mention “Calibration Due
          Date” in the certificate.
        </li>
        <li className={classes.termsboldCss}>
          - <strong>Insurance:</strong> We will not be responsible for damage or
          loss of item during calibration or during transit irrespective of all
          care that can be taken. Transit insurance will be provided by
          customers.
        </li>
        <li className={classes.termsboldCss}>
          - Kindly make RTGS/NEFT/IMPS as in below mentioned bank detail:
        </li>
        <li className={classes.termsboldCss}>
          <b className={classes.termsboldCss}>
            {" "}
            Bank Account Name: KESAR TESTING SERVICE LLP
          </b>
        </li>
        <li className={classes.termsboldCss}>
          <b className={classes.termsboldCss}> Bank Account No.: 303621010000114</b>
        </li>
        <li className={classes.termsboldCss}>
          <b className={classes.termsboldCss}> Bank Name: Union Bank of India</b>
        </li>
        <li className={classes.termsboldCss}>
          <b className={classes.termsboldCss}> Bank Branch: Vatva GIDC</b>
        </li>
        <li className={classes.termsboldCss}>
          <b className={classes.termsboldCss}> IFSC Code: UBIN0930369</b>
        </li>
      </ul>
      <b className={classes.termsboldCss}>Special Note: -</b>
      <ul>
        <li className={classes.termsboldCss}>
          - Bring with you all relevant and useful documents that are available
          with you while submitting the items / giving at site and before coming
          to your site. That will be returned to you after the completion of the
          job. The documents submitted along with the job will be maintained as
          a confidential document.
        </li>
        <li className={classes.termsboldCss}>
          - Please ensure that the instruments being submitted / given at site
          for calibration are in proper working condition and must be
          accompanied by the following:
          <ul type="a" style={{ listStyleType: "lower-alpha" }}>
            <li className={classes.termsboldCss}>
              a) Details of Item, Make, Model, ID No., Sr. No.
            </li>
            <li className={classes.termsboldCss}>
              b) Calibration Point and ranges to be calibrated
            </li>
            <li className={classes.termsboldCss}>
              c) Special accessories for calibration (to be specified
              separately)
            </li>
            <li className={classes.termsboldCss}>d) Operating manual</li>
            <li className={classes.termsboldCss}>
              e) Previous Calibration Certificate (Whenever possible)
            </li>
          </ul>
        </li>
        <li className={classes.termsboldCss}>
          - We hope the above is in line with your requirement and look forward
          to your favorable response as we are confident.
        </li>
        <li className={classes.termsboldCss}>
          - We can attend to your needs in the manner that keeps your
          competitive position in the market strong.
        </li>
        <li className={classes.termsboldCss}>
          - Thanking you and assuring you our best services always, we remain.
        </li>
        <li className={classes.termsboldCss}>
          - If there are any other types of list of instruments you have, kindly
          share with us for a better offer.
        </li>
        <li className={classes.termsboldCss}>
          - We are waiting for your valuable reply for the same.
        </li>
      </ul>
      <br />
      <b className={classes.termsboldCss}>Yours Sincerely,</b> <br />
      <b className={classes.termsboldCss}>For </b>
      <br />
      <b className={classes.termsboldCss}>KESAR TESTING SERVICE LLP</b>
      <br />
    </div>
  );
};